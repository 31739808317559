export const dataPolicy = [
  {
    label: `Общие положения`,
    list: [
      `Настоящая политика обработки персональных данных составлена в&nbsp;соответствии с&nbsp;требованиями Федерального закона от&nbsp;27.07.2006. &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; и&nbsp;определяет порядок обработки персональных данных и&nbsp;меры по&nbsp;обеспечению безопасности персональных данных, предпринимаемые СтарДент (далее&nbsp;&mdash; Оператор)`,
      `Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей деятельности соблюдение прав и&nbsp;свобод человека и&nbsp;гражданина при обработке его персональных данных, в&nbsp;том числе защиты прав на&nbsp;неприкосновенность частной жизни, личную и&nbsp;семейную тайну`,
      `Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта http://kashalotclinic.ru`,
    ]
  },
  {
    label: `Основные понятия, используемые в Политике`,
    list: [
      `Автоматизированная обработка персональных данных&nbsp;&mdash; обработка персональных данных с&nbsp;помощью средств вычислительной техники`,
      `Блокирование персональных данных&nbsp;&mdash; временное прекращение обработки персональных данных (за&nbsp;исключением случаев, если обработка необходима для уточнения персональных данных)`,
      `Веб-сайт&nbsp;&mdash; совокупность графических и&nbsp;информационных материалов, а&nbsp;также программ для ЭВМ и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети интернет по&nbsp;сетевому адресу http://kashalotclinic.ru`,
      `Информационная система персональных данных&nbsp;&mdash; совокупность содержащихся в&nbsp;базах данных персональных данных, и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий и&nbsp;технических средств`,
      `Обезличивание персональных данных&nbsp;&mdash; действия, в&nbsp;результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных`,
      `Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий (операций), совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств с&nbsp;персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных`,
      `Оператор&nbsp;&mdash; государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с&nbsp;другими лицами организующие&nbsp;и (или) осуществляющие обработку персональных данных, а&nbsp;также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с&nbsp;персональными данными`,
      `Персональные данные&nbsp;&mdash; любая информация, относящаяся прямо или косвенно к&nbsp;определенному или определяемому Пользователю веб-сайта http://kashalotclinic.ru`,
      `Пользователь&nbsp;&mdash; любой посетитель веб-сайта http://kashalotclinic.ru`,
      `Предоставление персональных данных&nbsp;&mdash; действия, направленные на&nbsp;раскрытие персональных данных определенному лицу или определенному кругу лиц`,
      `Распространение персональных данных&nbsp;&mdash; любые действия, направленные на&nbsp;раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на&nbsp;ознакомление с&nbsp;персональными данными неограниченного круга лиц, в&nbsp;том числе обнародование персональных данных в&nbsp;средствах массовой информации, размещение в&nbsp;информационно-телекоммуникационных сетях или предоставление доступа к&nbsp;персональным данным каким-либо иным способом`,
      `Трансграничная передача персональных данных&nbsp;&mdash; передача персональных данных на&nbsp;территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу`,
      `Уничтожение персональных данных&nbsp;&mdash; любые действия, в&nbsp;результате которых персональные данные уничтожаются безвозвратно с&nbsp;невозможностью дальнейшего восстановления содержания персональных данных в&nbsp;информационной системе персональных данных&nbsp;и (или) уничтожаются материальные носители персональных данных`,
    ]
  },
  {
    label: `Оператор может обрабатывать следующие персональные данные Пользователя`,
    list: [
      `Фамилия, имя, отчество`,
      `Номера телефонов`,
      `Также на&nbsp;сайте происходит сбор и&nbsp;обработка обезличенных данных о&nbsp;посетителях (в&nbsp;т.ч. файлов &laquo;cookie&raquo;) с&nbsp;помощью сервисов интернет-статистики (Яндекс Метрика и&nbsp;Гугл Аналитика и&nbsp;других)`,
      `Вышеперечисленные данные далее по&nbsp;тексту Политики объединены общим понятием Персональные данные`,
    ]
  },
  {
    label: `Цели обработки персональных данных`,
    list: [
      `Цель обработки персональных данных Пользователя&nbsp;&mdash; информирование Пользователя посредством отправки электронных писем`,
      `Также Оператор имеет право направлять Пользователю уведомления о&nbsp;новых продуктах и&nbsp;услугах, специальных предложениях и&nbsp;различных событиях. Пользователь всегда может отказаться от&nbsp;получения информационных сообщений, направив Оператору письмо на&nbsp;адрес электронной почты mail@kashalotclinic.ru с&nbsp;пометкой &laquo;Отказ от&nbsp;уведомлений о&nbsp;новых продуктах и&nbsp;услугах и&nbsp;специальных предложениях&raquo;`,
      `Обезличенные данные Пользователей, собираемые с&nbsp;помощью сервисов интернет-статистики, служат для сбора информации о&nbsp;действиях Пользователей на&nbsp;сайте, улучшения качества сайта и&nbsp;его содержания`,
    ]
  },
  {
    label: `Правовые основания обработки персональных данных`,
    list: [
      `Оператор обрабатывает персональные данные Пользователя только в&nbsp;случае их&nbsp;заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на&nbsp;сайте http://kashalotclinic.ru. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с&nbsp;данной Политикой`,
      `Оператор обрабатывает обезличенные данные о&nbsp;Пользователе в&nbsp;случае, если это разрешено в&nbsp;настройках браузера Пользователя (включено сохранение файлов &laquo;cookie&raquo; и&nbsp;использование технологии JavaScript)`,
    ]
  },
  {
    label: `Порядок сбора, хранения, передачи и других видов обработки персональных данных`,
    list: [
      `Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и&nbsp;технических мер, необходимых для выполнения в&nbsp;полном объеме требований действующего законодательства в&nbsp;области защиты персональных данных`,
      `Оператор обеспечивает сохранность персональных данных и&nbsp;принимает все возможные меры, исключающие доступ к&nbsp;персональным данным неуполномоченных лиц`,
      `Персональные данные Пользователя никогда, ни&nbsp;при каких условиях не&nbsp;будут переданы третьим лицам, за&nbsp;исключением случаев, связанных с&nbsp;исполнением действующего законодательства`,
      `В&nbsp;случае выявления неточностей в&nbsp;персональных данных, Пользователь может актуализировать их&nbsp;самостоятельно, путем направления Оператору уведомление на&nbsp;адрес электронной почты Оператора mail@kashalotclinic.ru с&nbsp;пометкой &laquo;Актуализация персональных данных&raquo;`,
      `Срок обработки персональных данных является неограниченным. Пользователь может в&nbsp;любой момент отозвать свое согласие на&nbsp;обработку персональных данных, направив Оператору уведомление посредством электронной почты на&nbsp;электронный адрес Оператора mail@kashalotclinic.ru с&nbsp;пометкой &laquo;Отзыв согласия на&nbsp;обработку персональных данных&raquo;`,
    ]
  },
  {
    label: `Трансграничная передача персональных данных`,
    list: [
      `Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных обязан убедиться в&nbsp;том, что иностранным государством, на&nbsp;территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных`,
      `Трансграничная передача персональных данных на&nbsp;территории иностранных государств, не&nbsp;отвечающих вышеуказанным требованиям, может осуществляться только в&nbsp;случае наличия согласия в&nbsp;письменной форме субъекта персональных данных на&nbsp;трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных`,
    ]
  },
  {
    label: `Заключительные положения`,
    list: [
      `Пользователь может получить любые разъяснения по&nbsp;интересующим вопросам, касающимся обработки его персональных данных, обратившись к&nbsp;Оператору с&nbsp;помощью электронной почты mail@kashalotclinic.ru`,
      `В&nbsp;данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до&nbsp;замены ее&nbsp;новой версией`,
      `Актуальная версия Политики в&nbsp;свободном доступе расположена в&nbsp;сети Интернет по&nbsp;адресу http://kashalotclinic.ru/policy`,
    ]
  },
]
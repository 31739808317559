export const reviewsData = {
  banner: {
    bgImage: 'reviews__banner@desktop.jpg',
    bgImageMobile: 'reviews__banner@mobile.jpg',
    bgImageTablet: 'reviews__banner@mobile.jpg',
    title: 'Отзывы',
  },
  list: [
    {
      type: 'cards',
      list: [
        {
          name: 'Юлия Качалова',
          date: '2021 год',
          text: 'В&nbsp;2021&nbsp;году, я&nbsp;проходила длительное лечение в&nbsp;клинике &laquo;Кашалот&raquo;. Доктор Максим Сергеевич сделал мне новую, красивую и&nbsp;белоснежнуюулыбку. До&nbsp;сих пор не&nbsp;могу поверить, что она моя! Огромное спасибо! Всем рекомендую. Моя старшая дочь, кстати, лечится у&nbsp;доктора Лали Нодариевны.',
          specialist: 'Москалев Максим Сергеевич',
        },
        {
          name: 'Игорь Ковалев',
          date: 'декабрь 2021',
          text: 'Много лет нашу семью ведёт супер доктор: Максим Сергеевич! Его протезы просто чудо! По&nbsp;очень умеренным ценам! Спасибо! И&nbsp;в&nbsp;этом году открыли для внука супер доктора Лали!!! Она победила нас своим профессионализмом и&nbsp;подходом к&nbsp;детям! Здоровья, Добра и&nbsp;Счастья в&nbsp;вашей семейной профессиональной жизни!!',
          specialist: 'Москалев Максим Сергеевич, Москалева Лали Нодариевна',
        },
        {
          name: 'Татьяна Фищук',
          date: 'январь 2022',
          text: 'Лечение и&nbsp;протезирование, всё понравилось. Отзывчивые работники клиники, готовые помочь даже если это не&nbsp;в&nbsp;их&nbsp;компетенции. Улыбчивый и&nbsp;доброжелательный администратор и&nbsp;врачи. Большое спасибо главному врачу Москалёву Максиму Сергеевичу и&nbsp;стоматологу Ксении Андреевна.',
          specialist: 'Москалев Максим Сергеевич, Панова Ксения Андреевна',
        },
        {
          name: 'Анна',
          date: 'март 2022',
          text: 'Любимая клиника. С удовольствием хожу к Лали на лечение и профилактику. А Максим Сергеевич ювелирно поставил имплант без дополнительной операции на челюсти, на которой настаивали другие специалисты. Очень нравится атмосфера: сложно бояться в клинике, где на стенах прекрасные картины и звучит музыка:)',
          specialist: 'Москалев Максим Сергеевич, Москалева Лали Нодариевна',
        },
        {
          name: 'Надежда',
          date: 'ноябрь 2021',
          text: 'Готовы ехать через все Москву к&nbsp;доктору Лали, она единственная кто так быстро нашел подход к&nbsp;дочке. Приятная распологающая атмосфера, чего нет в&nbsp;других клиниках. Образованные врачи все расскажут и&nbsp;объяснят. Персонал очень приветливый, чеки и&nbsp;все документы, все по-белому. Вылечели все зубки, второго ребенка и&nbsp;себя только сюда. Спасибо таким честным и&nbsp;грамотным врачам!!',
          specialist: 'Москалева Лали Нодариевна',
        },
        {
          name: 'Светлана',
          date: 'апрель 2022',
          text: 'Добрый день! Мы&nbsp;были у&nbsp;вас в&nbsp;январе месяце на&nbsp;консультации с&nbsp;сыном 3,5&nbsp;года. Нам в&nbsp;сентябре будет 4&nbsp;и&nbsp;мы&nbsp;каждый год проходим всех врачей сами. Но&nbsp;после знакомства с&nbsp;вами ни&nbsp;к&nbsp;какому стоматологу больше не&nbsp;хотим!',
          specialist: 'Москалева Лали Нодариевна',
        },
      ]
    },
    {
      type: `banner`,
      name: `Ирина Новова`,
      date: `21 апреля 2022 года`,
      quote: `Спасибо за ваши добрые руки и чуткие сердца!`,
      photo: `reviews__photo@desktop.png`
    },
    {
      type: 'cards',
      list: [
        {
          name: 'Виктория',
          date: 'май 2022',
          text: 'Добрый день! После приема сын вышел на&nbsp;улицу и&nbsp;говорит: &laquo;Мне так приятно, когда Лали мне зубы лечит&raquo;. Спасибо большое! После вчерашнего визита я&nbsp;четко осознала, что сын прошел через свою первую травму со&nbsp;страшным удалением у&nbsp;врача-монстра. Теперь при словах &laquo;надо лечиь зубы&raquo; его не&nbsp;бросает в&nbsp;пот.',
          specialist: 'Москалева Лали Нодариевна',
        },
        {
          name: 'Наталья',
          date: 'февраль 2022',
          text: 'Как&nbsp;же я&nbsp;благодарна, что однажды мы&nbsp;попали к&nbsp;замечательному доктору, что дети без страха идут в&nbsp;кресло лечить зубы. Ведь только она знает как я&nbsp;стрессую, оказываясь в&nbsp;кресле. Сегодня первый раз Варвара сидела самостоятельно, не&nbsp;на&nbsp;наших коленях и&nbsp;даже не&nbsp;пикнула. Пусть и&nbsp;дальше будет так. Любим и&nbsp;бесконечно благодарим!',
          specialist: 'Москалева Лали Нодариевна',
        },
        {
          name: 'Елена Камышова',
          date: '2021',
          text: 'У&nbsp;меня новая улыбка! Большое спасибо! Ваш индивидуальный подход, художественный вкус, желание сделать не&nbsp;просто красиво, но&nbsp;еще и&nbsp;как лучше для пациента мне очень нравятся! Я&nbsp;готова продолжать совершенствовать свою улыбку только с&nbsp;Вами!',
          specialist: 'Москалев Максим Сергеевич, Москалева Лали Нодариевна',
        },
        {
          name: 'Нелли',
          date: 'декабрь 2021',
          text: 'Хочу от&nbsp;всей души поблагодарить доктора Лали. Мы&nbsp;с&nbsp;сыном долго собирались к&nbsp;стоматологу, боялись и&nbsp;я, и&nbsp;он. Зная вредный характер своего пятилетнего сына, я&nbsp;не&nbsp;верила в&nbsp;успех, что все получится и&nbsp;он&nbsp;даст полечить зубы. Но&nbsp;Лали сделала невозможное, спокойно, без суеты все показала, рассказала ребёнку, что будет делать, дала понажимать кнопочки&nbsp;и, пока сын обдумывал процесс поступления воды в&nbsp;краник, зуб был вылечен. Даже я&nbsp;не&nbsp;знаметила, так всё быстро и&nbsp;профессионально было сделано. И&nbsp;главное без единого крика и&nbsp;слезинки ребенка. Я&nbsp;сама лечила зубы у&nbsp;Лали 15&nbsp;лет назад и&nbsp;все промбы до&nbsp;сих пор на&nbsp;месте. Еще раз огромное спасибо! Доктор Лали&nbsp;&mdash; настоящий мастер своего дела!',
          specialist: 'Москалева Лали Нодариевна',
        },
        {
          name: 'Ксения',
          date: 'январь 2022',
          text: 'Я&nbsp;так счастлива найти такого стоматолога как Лали! Это просто подарок, честное слово! Так классно, что мой сын с&nbsp;удовольствием всегда едет на&nbsp;приём. Классно, что у&nbsp;Сашки не&nbsp;будет таких страхов как у&nbsp;меня с&nbsp;детства и&nbsp;только с&nbsp;посещением Лали и&nbsp;мои страхи постепенно исчезают... спасибо! Спасибо за&nbsp;советы по&nbsp;гигиене, мы&nbsp;купили классые щётки, поменяли пасту так как в&nbsp;первый приём, когда мне показали все недочеты, я&nbsp;была просто обескуражена. Сейчас сын чистит зубы полностью сам и&nbsp;Лали сказала, что зачётно! Йохуууу!',
          specialist: 'Москалева Лали Нодариевна',
        },
        {
          name: `Светлана`,
          text: 'Так случилось, что мне катастрофически не&nbsp;везло со&nbsp;стоматологами, но&nbsp;чуткие руки Пановой Ксении Андреевны изменили моё отношение к&nbsp;стоматологии. Лечение прошло комфортно и&nbsp;профессионально! Ксения всё обхясняла на&nbsp;каждом этапе. Ксения, спасибо за&nbsp;вашу внимательность и&nbsp;профессионализм, вы&nbsp;очень хороший специалист и&nbsp;замечательный человек. Спасибо!',
          specialist: 'Панова Ксения Андреевна',
        },
        {
          name: `Яна`,
          text: 'Хочу выразить багодарность Ксении Андреевне Пановой, она замечательный специалист! Наблюдаюсь в&nbsp;клинике с&nbsp;августа 2021, прохожу комплекстное лечение. Попала к&nbsp;ней на&nbsp;приём случайно и&nbsp;это была судьба! Процедура всегда проходит безболезненно, спокойно, прихожу с&nbsp;удовольствием! Она избавила меня от&nbsp;страха перед стоматологами, который был у&nbsp;меня всю жизнь! Рада, что нашла своего самого лучшего стоматолога!',
          specialist: 'Панова Ксения Андреевна',
          preview: `review-Яна.webp`
        },
        {
          name: `@Shuraohman_`,
          text: 'Ксения&nbsp;&mdash; профессионал своего дела! Если у&nbsp;вас болят зубы, вам к&nbsp;Ксении! Потрясающий специалист! Спасибо большое, что помогли мне победить дентофобию! Я&nbsp;даже не&nbsp;могла подумать, что приём у&nbsp;врача&nbsp;&mdash; стоматолога может пройти настолько комфортно и&nbsp;безоболезненно!',
          specialist: 'Панова Ксения Андреевна',
        },
      ]
    },
  ],
}
export const faqData = {
  banner: {
    bgImage: 'faq__banner@desktop.jpg',
    bgImageTablet: 'faq__banner@mobile.jpg',
    bgImageMobile: 'faq__banner@mobile.jpg',
    title: 'Частые вопросы и ответы',
  },
  faqList: [
    {
      key: `Что такое зубной имплантат?`,
      value: `
          <p>Зубной имплантат представляет собой винт,
заменяющий корень утраченного зуба.
Он устанавливается в челюсть и используется
в качестве опоры для коронки, мостовидного
протеза или иной ортопедической конструкции.
Самый распространенный материал для изготовления
имплантататов, очень прочный и биосовместимый
металл – титан.</p>
        `
    },
    {
      key: `Сколько длится лечение адентии методом имплантации?`,
      value: `
          <p>Сроки лечения зависят от клинической ситуации
каждого конкретного пациента. Мы составим
для вас индивидуальный план лечения с учетом ваших
потребностей. В среднем лечение занимает от 3 до 6
месяцев. Перед установкой постоянного протеза может
потребоваться изготовление временной реставрации,
чтобы минимизировать дискомфорт и добиться
оптимального эстетического результата.</p>
      `
    },
    {
      key: `Имплантация - это больно?`,
      value: `
          <p>Непосредственно сама операция по установке одного
имплантата длится от 15 до 40 минут и проводится
под местной анестезией или седацией.
Вас подготовят к операции и сделают анестезию,
поэтому установка имплантатов пройдет безболезненно.
В большинстве случаев уже через несколько часов
после операции вы сможете вернуться к работе и
привычному образу жизни. Если вы будете испытывать
боль после хирургического вмешательства, вам назначат
обезболивающее.</p>
        `
    },
    {
      key: `Как подготовиться к имплантации?`,
      value: `
          <p>Особой подготовки не требуется. Советуем хорошо
выспаться, сытно поесть за 2-3 часа до операции
(но не переедать) и приехать в клинику в хорошем
настроении. За пару дней до операции рекомендуем
отказаться от алкоголя, кофе и крепкого чая.
Непосредственно перед операцией можно пить только воду.
Более подробные рекомендации вы получите на консультации.</p>
        `
    },
    {
      key: `Что необходимо делать после операции по имплантации?`,
      value: `
        <p>
          <b>Отек:</b> используйте холод
        </p>
        <p>
          Чтобы предотвратить отек и боль, сразу после операции приложите лед с той стороны, где проходило хирургическое вмешательство.
        </p>
        <p>
          <b>Боль:</b> принимайте назначенные лекарственные препараты
        </p>
        <p>
          Если стоматолог назначил вам какие-либо препараты, не забывайте их принимать.
        </p>
        <p>
          <b>Полоскания:</b> соблюдайте осторожность
        </p>
        <p>
          В течение первых часов после операции не следует полоскать рот, поскольку это может вызвать кровотечение. Однако после имплантации вы можете попить воды.
        </p>
        <p>
          <b>Временная реставрация</b> требует ухода
        </p>
        <p>
          Пока имплантаты приживаются, на них может быть зафиксирован
формирователь десны или временный протез. Формирователь десны
закрывает имплантат, пока окружающие его ткани заживают. Постарайтесь
не жевать твердую пищу той стороной челюсти, где установлен имплантат.
Это предотвратит осложнения.
        </p>
      `
    },
  ],
}
export const dataSocial = [
  {
    type: 'telegram',
    url: 'https://t.me/+79269652202'
  },
  {
    type: 'whatsapp',
    url: 'https://wa.me/+79269652202'
  },
]
